var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageTitle", [_vm._v(" Debug ")]),
      _c("dl", [
        _c("dt", [_vm._v("Version")]),
        _c("dd", [_vm._v(_vm._s(_vm.buildVersion))]),
        _c("dt", [_vm._v("Date")]),
        _c("dd", [_vm._v(_vm._s(_vm.buildTime))]),
        _c("dt", [_vm._v("Backend")]),
        _c("dd", [_vm._v(_vm._s(_vm.backend))])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }